export enum ErrorStatusCode {
  CAPTCHA_VERIFICATION_FAILED = 1048,
}

export enum OutOfStockError {
  SOLD_OUT = 'sold_out',
  OUT_OF_STOCK = 'out_of_Stock',
  IS_SOLD_OUT = 'is_sold_out',
  TOO_MANY = 'too_many',
}
