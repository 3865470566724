import React, { useMemo, useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import {
  MyCheckApp,
  useAuthStore,
  useCheckoutStore,
  useErrorTrackerHandler,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon, CustomInput, Icon, Loader, Overlay } from '@components';

import { CustomButton } from '../../../../../components/CustomButton/CustomButton';

import styles from './CouponModal.module.scss';
import { CardSummary, Tax } from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { CartApiError } from 'types/GenericTypes';
import useOutOfStockErrorCallback from 'core/hooks/useOutOfStockErrorCallback';

interface Props {
  handleModalOpen: () => void;
  onCouponVerify: (summary: CardSummary, taxes: Array<Tax>) => void;
  initStep?:
    | 'add'
    | 'applying'
    | 'removing'
    | 'done'
    | 'error'
    | 'general-error';
}

export const CouponModal: React.FC<Props> = ({
  handleModalOpen,
  onCouponVerify,
  initStep,
}) => {
  const { t } = useTranslation();
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const AuthStore = useAuthStore();
  const { isLg } = useWindowSize();
  const { onError } = useErrorTrackerHandler();
  const { outOfStockErrorCallback } = useOutOfStockErrorCallback();

  const [step, setStep] = useState<
    'add' | 'applying' | 'removing' | 'done' | 'error' | 'general-error'
  >(initStep || 'add');
  const [statusText, setStatusText] = useState<string>('');
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const getValidationSchema = () =>
    yup.object().shape({
      couponCode: yup.string().required(),
    });

  const initValues: {
    couponCode: string;
  } = {
    couponCode: '',
  };

  const onClose = () => {
    document.body.style.removeProperty('overscroll-behavior-y');
    document.body.style.removeProperty('touch-action');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');
    handleModalOpen();
  };

  const onClickOutside = () => {
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const onSubmit = async ({ couponCode }) => {
    switch (step) {
      case 'error':
      case 'add':
        setStep('applying');

        const businessId = LocationStore.selectedRestaurantId;
        const experienceId = LocationStore.selectedExperience.id;
        const accessToken = AuthStore.accessToken;
        const { summary, taxes } = await CheckoutStore.updateOrCreateCheckout({
          accessToken,
          businessId,
          experienceId,
          totalCalculation: true,
          couponCode: couponCode,
        }).catch((error) => {
          if (
            error.code === 4247 &&
            error.message === 'CART_INVALID_COUPON_CODE' &&
            error.status === 'ERROR'
          ) {
            setStep('error');
          } else {
            setStep('general-error');
          }
          CheckoutStore.removeCoupon();
          if (error instanceof CartApiError) {
            onError(error, () => outOfStockErrorCallback(error));
          }
        });
        onCouponVerify(summary, taxes);
        setStep('done');
        break;
      case 'general-error':
      case 'done':
        handleModalOpen();
        break;
    }
  };

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
    };
  }, []);

  useEffect(() => {
    if (step) {
      switch (step) {
        case 'applying':
          setStatusText(t('checkout.coupon.applying'));
          break;
        case 'removing':
          setStatusText(t('checkout.coupon.removing'));
          break;
        case 'done':
          setStatusText(t('checkout.coupon.applied'));
          break;
        case 'general-error':
          setStatusText(t('checkout.coupon.errors.general'));
          break;
      }
    }
  }, [step]);

  const renderBody = () => (
    <>
      <div className={styles.couponModalContainerHeader}>
        <div className={styles.couponModalContainerHeaderTitle}>
          {t('checkout.coupon.title')}
        </div>
        <div
          className={styles.couponModalContainerHeaderExit}
          onClick={onClose}
        >
          <Icon name="x" />
        </div>
      </div>
      <Formik
        initialValues={initValues}
        onSubmit={onSubmit}
        validationSchema={getValidationSchema}
      >
        {(formikProps) => (
          <Form
            className={
              step === 'done'
                ? styles.couponModalContainerFormDone
                : step === 'general-error'
                  ? styles.couponModalContainerFormError
                  : styles.couponModalContainerForm
            }
          >
            {['add', 'error'].includes(step) && (
              <CustomInput
                className={styles.couponModalContainerCustomInput}
                onChange={formikProps.handleChange}
                value={formikProps.values.couponCode}
                name={'couponCode'}
                errorMessage={t('checkout.coupon.errors.notValid')}
                error={!!formikProps.errors.couponCode || step === 'error'}
                placeholder={t('checkout.coupon.enter')}
                placeHolderStyle={{ left: 43 }}
                type={'text'}
                /*maxLength={LocationStore.uiCheckoutSettings.first_name.max}*/
                testId="coupon-code-input"
              >
                <CustomIcon
                  name="coupons.logo"
                  className={styles.couponModalContainerCustomInputIcon}
                  style={{ color: config.primary }}
                />
              </CustomInput>
            )}

            {['applying', 'removing'].includes(step) && (
              <div className={styles.couponModalContainerStatus}>
                <Loader withOverlay={false} label={statusText} />
              </div>
            )}

            {step === 'done' && (
              <div
                className={styles.couponModalContainerStatus}
                style={{ marginBottom: 38 }}
              >
                <CustomIcon
                  name="coupons.applied"
                  className={styles.couponModalContainerStatusIcon}
                  style={{ paddingBottom: 8 }}
                />
                <span className={styles.couponModalContainerStatusText}>
                  {statusText}
                </span>
              </div>
            )}

            {step === 'general-error' && (
              <div
                className={styles.couponModalContainerStatus}
                style={{ marginBottom: 24 }}
              >
                <CustomIcon
                  name="coupons.unhappy"
                  className={styles.couponBoxIcon}
                  style={{ color: config.primary, paddingBottom: 8 }}
                />
                <span className={styles.couponModalContainerStatusText}>
                  {statusText}
                </span>
              </div>
            )}

            {step !== 'removing' && (
              <CustomButton
                text={
                  ['done', 'general-error'].includes(step)
                    ? t('general.ok')
                    : t('general.apply')
                }
                handleButtonClick={formikProps.handleSubmit}
                className={styles.couponModalContainerCustomButton}
                disabled={step === 'applying'}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
  if (isLg) {
    return (
      <Overlay
        width={358}
        height={312}
        className={styles.couponModalContainer}
        onClickOutside={onClickOutside}
      >
        {renderBody()}
      </Overlay>
    );
  }
  return (
    <Overlay
      width={'100%'}
      className={styles.couponModalContainer}
      onClickOutside={onClickOutside}
    >
      {renderBody()}
    </Overlay>
  );
};
