import { MenuItemType } from 'plugins/MenuPlugin/types/MenuItemType';

type MyCheckApiErrorObject = {
  status: 'ERROR';
  code: number;
  message: string;
  info?: string;
};

export type ItemsNotInStockType = {
  pui: string;
  name: string;
  is_sold_out: boolean;
  is_out_of_stock: boolean;
  total_in_stock: number;
};

//@ts-expect-error FIGURE OUT THIS MAGIC ERROR TYPE
export type MyCheckApiError =
  | MyCheckApiErrorObject
  | ({
      readonly message: 'CART_CHECKOUT_ITEM_OUT_OF_STOCK';
      itemsNotInStock: ItemsNotInStockType[];
    } & MyCheckApiError);

type MenuIds = {
  id: number;
  bid: number;
  pos_unique_id: string;
  serial_id: string;
  name: string;
  under_18: number | null;
  type: MenuItemType;
  kcal?: number | null;
};

export type MenuCategoryType = MenuIds & {
  subcategories: MenuSubcategory[];
  items?: Array<MenuItem>;
};

export type MenuSubcategory = MenuIds & {
  items: Array<MenuItem>;
  subcategories?: MenuSubcategory[];
};

export type MenuItem = MenuIds & {
  allergens: number[];
  image: string;
  description: string;
  price: number;
  stock: boolean;
  saleIds: string[];
  saleText: string;
  modifierGroups: MenuModifierGroup[];
  modifiers: MenuModifier;
  is_hidden: boolean | null;
};

export type MenuModifierGroup = MenuIds & {
  name: string;
  modifiers: MenuModifier[];
  minSelect: number;
  maxSelect: number;
};

export type MenuModifier = MenuIds & {
  name: string;
  price: number;
  stock: number;
  allergens: number[];
};

export type DynamicValue = {
  [key: string]: string;
};
export type DynamicValueArray = {
  [key: string]: string[];
};

export type GenericRefProps = {
  getId: () => number;
  scrollIntoView: () => void;
};

export interface IConfigTimeSettings {
  interval: number;
}

export interface GenericSelectValueItem {
  label: string;
  value: string;
}

export interface DaysSelectValueItem {
  [key: number | string]: Array<GenericSelectValueItem>;
}

export type CartApiErrorType = Error & {
  code: number;
  items_not_in_stock?: unknown[];
};

export class CartApiError extends Error {
  items_not_in_stock?: unknown[];
  code?: number;

  constructor(error: CartApiErrorType) {
    super(error.message);
    this.stack = error.stack;
    this.code = error.code;
    this.items_not_in_stock = error.items_not_in_stock;

    Object.setPrototypeOf(this, CartApiError.prototype);
  }
}
