import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation, ApiClient } from 'mycheck-core';

import { ClosePage, Overlay, PrimaryButton } from '@components';

import {
  useAuthStore,
  useLanguageStore,
} from '../../../core/stores/useStoreHooks';

import styles from './ErrorModalTracker.module.scss';
import { ItemsNotInStockType, MyCheckApiError } from 'types/GenericTypes';
import { ErrorStatusCode, OutOfStockError } from './constants';
import { getKioskStatus } from 'core/core/store/kiosk/slice';
import { useAppSelector } from 'core/core/store/store';
import { isOutOfStockError } from './utils';

type ErrorTrackerContextType = {
  onError: (err: MyCheckApiError, callbackError?: () => void) => void;
};

const errorTrackerContext: ErrorTrackerContextType = {
  onError: (_err, _callbackError) => null,
};

const ErrorTrackerContext = createContext(errorTrackerContext);

export function useErrorTrackerHandler() {
  const context = useContext(ErrorTrackerContext);
  return { onError: context.onError };
}

export const ErrorModalTracker = ({ children }) => {
  const isKioskMode = useAppSelector(getKioskStatus);
  const { t, i18n } = useTranslation();
  const AuthStore = useAuthStore();
  const location = useLocation();
  const LanguageStore = useLanguageStore();

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(t('errors.defaultError') as string);
  const [callback, setCallback] = useState<() => void>();

  const closeModal = () => {
    setShowModal(false);
    callback();
  };

  const reportMissingKey = async (err: MyCheckApiError) => {
    try {
      if (!!err.message && !!err.code && !!err.info) {
        await ApiClient.instance.post<Array<Record<string, unknown>>>(
          `/restaurants/api/v3/translations/${AuthStore.businessID}/${LanguageStore.selectedValue}/missed`,
          [{ message: err.message, code: err.code, info: err.info }],
        );
      }
    } catch (_err) {}
  };

  const onErrorMessage = (err: MyCheckApiError) => {
    const errorKey = `errors.${err.message}`;

    if (err.code === ErrorStatusCode.CAPTCHA_VERIFICATION_FAILED) {
      return t(`errors.captchaVerification`);
    }

    if (!i18n.exists(errorKey)) {
      reportMissingKey(err);
      return t(`errors.defaultError`);
    }

    if (isOutOfStockError(err.message)) {
      const itemsNotInStockError = err as {
        items_not_in_stock: ItemsNotInStockType[];
      };

      const errorStatusHandler = {
        [OutOfStockError.SOLD_OUT]: (item) =>
          t('errors.CALC_COUNT_SOLD_OUT', { itemName: item.name }),
        [OutOfStockError.OUT_OF_STOCK]: (item) =>
          t('errors.CALC_COUNT_OOS', { itemName: item.name }),
        [OutOfStockError.TOO_MANY]: (item) =>
          t('errors.CALC_COUNT_TOO_MANY', {
            count: item.total_in_stock,
            itemName: item.name,
          }),
      };

      const listOfItems = itemsNotInStockError.items_not_in_stock
        .map((oosItem) => {
          if (!oosItem.hasOwnProperty(OutOfStockError.IS_SOLD_OUT)) {
            return oosItem.name;
          }
          const soldOutKey = oosItem.is_sold_out && OutOfStockError.SOLD_OUT;
          const outOfStockKey =
            oosItem.is_out_of_stock && OutOfStockError.OUT_OF_STOCK;
          const tooManyKey =
            !soldOutKey && !outOfStockKey && OutOfStockError.TOO_MANY;

          return errorStatusHandler[soldOutKey || outOfStockKey || tooManyKey](
            oosItem,
          );
        })
        .join(', ');

      if (listOfItems) {
        return t(errorKey, {
          listOfItems: listOfItems,
        });
      }
    }

    return t(errorKey);
  };

  const onError = (err: MyCheckApiError, callbackError = () => null) => {
    if (!axios.isCancel(err)) {
      if (!isKioskMode) {
        setShowModal(true);
      }
      setMessage(onErrorMessage(err));
      setCallback(() => () => callbackError());
    }
  };

  const config = React.useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const mainTextStyle = get(_config, 'general.popup.mainText', {});

    return {
      mainTextStyle,
    };
  }, [location.search]);

  return (
    <ErrorTrackerContext.Provider value={{ onError }}>
      {children}
      {showModal && (
        <Overlay testId="error-modal" className={styles.overlay} isPopup>
          <div className={styles.root}>
            <ClosePage
              testId="error-modal"
              onClose={closeModal}
              customStylePath="general.popup.icons.x"
            />
            <div className={styles.wrapper}>
              <div
                style={config.mainTextStyle}
                dangerouslySetInnerHTML={{ __html: message }}
              />
            </div>
            <PrimaryButton onClick={closeModal}>
              {t('general.ok')}
            </PrimaryButton>
          </div>
        </Overlay>
      )}
    </ErrorTrackerContext.Provider>
  );
};
