import { isOutOfStockError } from 'core/components/ModalTrackers/ErrorModalTracker/utils';
import {
  useCheckoutStore,
  useMenuTrackerHandler,
  useNavigation,
} from 'mycheck-core';
import { CartApiError } from 'types/GenericTypes';

const useOutOfStockErrorCallback = () => {
  const CheckoutStore = useCheckoutStore();
  const navigation = useNavigation();
  const menuTracker = useMenuTrackerHandler();

  const outOfStockErrorCallback = async (
    error: CartApiError,
    navigateBack = true,
  ) => {
    if (isOutOfStockError(error.message)) {
      CheckoutStore.deleteItemAfterError(error.items_not_in_stock);
      if (navigateBack) {
        navigation.goBack();
      }
      await menuTracker.checkIsSameMenu(null, true);
    }
  };

  return { outOfStockErrorCallback };
};

export default useOutOfStockErrorCallback;
