import React, { useMemo, useEffect, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useAuthStore,
  useCheckoutStore,
  useErrorTrackerHandler,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { CustomIcon } from '@components';

import { success } from '../../../../core/styles/variables.module.scss';

import styles from './Coupon.module.scss';
import { CouponModal } from './CouponModal/CouponModal';
import { CardSummary, Tax } from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { CartApiError } from 'types/GenericTypes';
import useOutOfStockErrorCallback from 'core/hooks/useOutOfStockErrorCallback';

interface Props {
  onCouponVerify: (summary: CardSummary, taxes: Array<Tax>) => void;
}

export const Coupon: React.FC<Props> = ({ onCouponVerify }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const { onError } = useErrorTrackerHandler();
  const { outOfStockErrorCallback } = useOutOfStockErrorCallback();

  const [modalOpen, setModalOpen] = useState(false);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const [hasCoupon, setHasCoupon] = useState(!!CheckoutStore.getCoupon());
  const [initStep, setInitStep] = useState<
    'add' | 'applying' | 'removing' | 'done' | 'error' | 'general-error'
  >('add');

  const removeCoupon = async () => {
    setInitStep('removing');
    setModalOpen(true);
    CheckoutStore.removeCoupon();
    const accessToken = AuthStore.accessToken;
    const experienceId = LocationStore.selectedExperience.id;
    const businessId = LocationStore.selectedRestaurantId;
    try {
      const { summary, taxes } = await CheckoutStore.updateOrCreateCheckout({
        accessToken,
        businessId,
        experienceId,
        totalCalculation: true,
      });
      setHasCoupon(false);
      onCouponVerify(summary, taxes);
      setModalOpen(false);
      setInitStep('add');
    } catch (error) {
      if (error instanceof CartApiError) {
        onError(error, () => outOfStockErrorCallback(error as CartApiError));
      }
    }
  };

  useEffect(() => {
    setHasCoupon(!!CheckoutStore.getCoupon());
  }, [CheckoutStore.coupon]);

  return (
    <div className={styles.coupon}>
      <div
        className={classnames(
          styles.couponBox,
          hasCoupon ? styles.couponBoxVerify : '',
        )}
        style={!hasCoupon ? { color: config.primary } : {}}
        onClick={() => {
          if (!hasCoupon) {
            setModalOpen(true);
          }
        }}
      >
        <CustomIcon
          name="coupons.logo"
          className={classnames(
            styles.couponBoxIcon,
            hasCoupon ? styles.couponBoxVerify : '',
          )}
          style={!hasCoupon ? { color: config.primary } : {}}
        />
        <span className={styles.couponBoxName}>
          {hasCoupon
            ? CheckoutStore.getCoupon()?.name
            : t('checkout.coupon.add')}
        </span>
        {hasCoupon && (
          <div onClick={removeCoupon} className={styles.couponBoxIconClose}>
            <CustomIcon name="x" style={{ color: success }} />
          </div>
        )}
      </div>
      {modalOpen && (
        <CouponModal
          handleModalOpen={() => setModalOpen(false)}
          onCouponVerify={onCouponVerify}
          initStep={initStep}
        />
      )}
    </div>
  );
};
