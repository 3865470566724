import React, { createContext, useContext, useMemo, useState } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  useLocationStore,
  useCheckoutStore,
  useMenuStore,
  MyCheckApp,
  useLanguageStore,
  useTranslation,
  useErrorTrackerHandler,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './MenuTracker.module.scss';

type IsSameMenuCallbacks = {
  callback?: () => void;
  errCallback?: () => void;
};

type MenuTrackerContextType = {
  checkIsSameMenu: (
    callbacks?: IsSameMenuCallbacks,
    refetchMenu?: boolean,
  ) => Promise<void>;
};

const menuTrackerContext: MenuTrackerContextType = {
  checkIsSameMenu: (_callbacks, _refetchMenu) => null,
};

const MenuTrackerContext = createContext(menuTrackerContext);

export function useMenuTrackerHandler() {
  return useContext(MenuTrackerContext);
}

export const MenuTracker = ({ children }) => {
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();
  const CheckoutStore = useCheckoutStore();
  const LanguageStore = useLanguageStore();
  const errorHandler = useErrorTrackerHandler();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [callbackFunction, setFunctionCallback] = useState(() => null);

  const checkIsSameMenu = async (
    callbacks?: IsSameMenuCallbacks,
    refetchMenu: boolean = false,
  ) => {
    try {
      await MenuStore.selectCurrentMenu({
        bid: LocationStore.selectedRestaurant.id,
        menus: LocationStore.menuFiles,
        translations: LocationStore.menuTranslations,
        time: CheckoutStore.checkoutTime || LocationStore.selectedDate,
        timezone: LocationStore.locationTimezoneName,
        language: LanguageStore.selectedValue,
        refetchMenu,
      });

      if (CheckoutStore.removeUnavailableItems(MenuStore.items)) {
        setShowModal(true);
        if (!callbacks) return;

        setFunctionCallback(
          () => callbacks?.errCallback || callbacks?.callback,
        );
      } else {
        callbacks?.callback && callbacks.callback();
      }
    } catch (err) {
      errorHandler.onError(err);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    callbackFunction();
  };

  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});
    const bodyStyle = get(_config, 'general.popup.mainText', {});

    return {
      titleStyle,
      bodyStyle,
    };
  }, [location.search]);

  return (
    <MenuTrackerContext.Provider value={{ checkIsSameMenu }}>
      {children}
      {showModal && (
        <PopUp onClose={handleModalClose} testId="menu-tracker">
          <div className={styles.wrapper}>
            <div className={styles.title} style={config.titleStyle}>
              {t('checkout.titleMissingItems')}
            </div>
            <div style={config.bodyStyle}>{t('checkout.bodyMissingItems')}</div>
          </div>
        </PopUp>
      )}
    </MenuTrackerContext.Provider>
  );
};
