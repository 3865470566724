import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';
import React, { PropsWithChildren, useMemo, useRef } from 'react';

interface BackgroundProps extends PropsWithChildren {
  id?: string;
  secondary?: boolean;
  className?: string;
  style?: React.CSSProperties;
  onScroll?: (scrollTop: number) => void;
  testId?: string;
}

export const Background: React.FC<BackgroundProps> = ({
  id,
  secondary = false,
  children,
  className,
  style,
  onScroll,
  testId,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const { primaryBackground, secondaryBackground } = get(
      _config,
      'palette',
      {},
    );

    return {
      primaryBackground,
      secondaryBackground,
    };
  }, [location.search]);

  const myElementRef = useRef(null);
  const handleScroll = () => {
    const el = myElementRef.current;
    if (onScroll) {
      onScroll(el.scrollTop);
    }
  };

  return (
    <div
      onScroll={handleScroll}
      style={{
        backgroundColor: secondary
          ? config.secondaryBackground
          : config.primaryBackground,
        ...style,
      }}
      className={className}
      id={id}
      ref={myElementRef}
      data-test-id={testId}
    >
      {children}
    </div>
  );
};
