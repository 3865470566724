import React, { useEffect, useMemo, useState, type CSSProperties } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  capitalizeFirstLetter,
  displayTime,
  GaService,
  getDateLabel,
  MyCheckApp,
  useAuthStore,
  useCheckoutStore,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useNativeBridge,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Background, Header, Overlay, TranslateText } from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { CartStoreService } from '../../../../core/core/service/CartStoreService';

import styles from './ConfirmPage.module.scss';
import { ConfirmPageElement } from './ConfirmPageElement';

const setBasePath = () => {
  const first = window.location.pathname;
  const prefix = first.split('/')?.[1] || '';
  if (prefix) {
    return `/${prefix}`;
  }

  return '/';
};

export const ConfirmPage: React.FC = () => {
  const AuthStore = useAuthStore();
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const MenuStore = useMenuStore();
  const nativeBridge = useNativeBridge();
  const { isLg } = useWindowSize();
  const { t } = useTranslation();

  const [orderNumber] = useState(CheckoutStore.orderNumber);
  const [confirmationNumber] = useState(CheckoutStore.confirmationNumber);
  const [checkoutTime] = useState(CheckoutStore.checkoutTime);

  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();

    const titleStyle = get(_config, 'confirmation.title', {});
    const textStyle = get(_config, 'confirmation.text', {});
    const primary = get(_config, 'palette.primary', {});

    return {
      titleStyle,
      textStyle,
      blockHomePage,
      primary,
    };
  }, [location.search]);

  const onPressDone = () => {
    if (AuthStore.isGuestLoggedIn) {
      AuthStore.logout();
    }

    MenuStore.clearIsAdult();
    CartStoreService.clearStore();
    CheckoutStore.clearCheckoutWithTime();
    CheckoutStore.clearRoomTableParams();

    GaService.instance.sendEvent({
      category: 'Ordering - Confirmation',
      action: 'Done',
      label: 'click',
    });

    if (LocationStore.isNative || LocationStore.isIframe) {
      nativeBridge.postMessageClose({ action: 'CLOSE', source: 'checkout' });
    } else {
      const locationGroupPath = `/location?hotel=${LocationStore.selectedHotel}&location_group_id=${LocationStore.locationGroupId}`;
      const path = config.blockHomePage
        ? `/location?hotel=${LocationStore.selectedHotel}`
        : '';
      window.location.replace(
        `${setBasePath()}${
          LocationStore.locationGroupId ? locationGroupPath : path
        }`,
      );
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', onPressDone);
    window.addEventListener('beforeunload', onPressDone);
    window.onbeforeunload = onPressDone;
    window.onpopstate = onPressDone;
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';

    return () => {
      window.removeEventListener('popstate', onPressDone);
      window.removeEventListener('beforeunload', onPressDone);
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
    };
  }, []);

  const renderBody = () => {
    if (!Object.keys(LocationStore.selectedRestaurant).length) {
      return null;
    }

    const confirmData = [
      {
        title: t('confirm.orderNumberLabel'),
        value: orderNumber,
        isVisible: !!orderNumber,
      },
      {
        title: t('confirm.confirmationNumberLabel'),
        value: confirmationNumber,
        isVisible: !!confirmationNumber,
      },
      {
        title: t('confirm.deliveryTimeLabel'),
        value: (
          <TranslateText
            tKey="confirm.deliveryTimeValue"
            values={{
              when: getDateLabel(
                checkoutTime,
                LocationStore.locationTimezoneName,
                LocationStore.localDateFormat,
              ),
              at: displayTime(
                checkoutTime,
                LocationStore.locationTimezoneName,
                LocationStore.localTimeFormat,
              ),
            }}
          />
        ),
        isVisible:
          !LocationStore.selectedExperience.settings.is_asap_only ||
          LocationStore.selectedExperience.settings.display_order_time,
      },
      {
        title: t('confirm.contactLabel'),
        value: (
          <>
            <div>
              {LanguageStore.getBusinessTranslation(
                LocationStore.selectedHotelObj.id,
                'name',
                LocationStore.selectedHotelObj.name,
              )}
            </div>
            <div>
              {LanguageStore.getBusinessTranslation(
                LocationStore.selectedRestaurant.id,
                'name',
                LocationStore.selectedRestaurant.name,
              )}
            </div>
            <div>
              {LocationStore.selectedRestaurant.place.data.formatted_address}
            </div>
            <div>
              {isLg ? (
                LocationStore.selectedRestaurant.phone
              ) : (
                <a href={`tel:+${LocationStore.selectedRestaurant.phone}`}>
                  {LocationStore.selectedRestaurant.phone}
                </a>
              )}
            </div>
          </>
        ),
        isVisible: true,
      },
    ].filter((e) => e.value);

    const headerMobileStyle: CSSProperties = !isLg
      ? {
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          zIndex: 3,
        }
      : {
          backgroundColor: config.primary,
          color: '#ffffff',
          width: 480,
        };

    return (
      <div className={styles.confirmPage}>
        <Header
          enforceSmall
          color="#ffffff"
          headerTitle={t('confirm.confirmTitle')}
          handleBackClick={onPressDone}
          rootStyle={headerMobileStyle}
        />

        <Background secondary className={styles.confirmPageScroll}>
          <div
            className={styles.confirmPageThanks}
            style={{ color: config.primary }}
          >
            {t('confirm.thanks')}
          </div>
          <div className={styles.confirmPageDescription}>
            {LanguageStore.getExperienceTranslation(
              LocationStore.selectedExperience.id,
              'settings.ui.checkout.instructions',
              LocationStore?.uiCheckoutSettings.instructions,
            )}
          </div>
          {confirmData.map(
            (confirmField) =>
              confirmField.isVisible && (
                <ConfirmPageElement
                  key={confirmField.title}
                  title={confirmField.title}
                  value={confirmField.value}
                />
              ),
          )}
        </Background>

        <CustomButton
          text={capitalizeFirstLetter(t('confirm.button').toLowerCase())}
          className={styles.confirmPageCustomButton}
          handleButtonClick={onPressDone}
          testId="confirm-done"
        />
      </div>
    );
  };

  if (isLg) {
    return (
      <Overlay
        width={480}
        height={780}
        testId="confirm-page"
        onClickOutside={onPressDone}
      >
        {renderBody()}
      </Overlay>
    );
  }

  return renderBody();
};
