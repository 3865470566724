import React, { useMemo } from 'react';

import classnames from 'classnames';
import parsePhoneNumber from 'libphonenumber-js';
import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import {
  capitalizeFirstLetter,
  displayTimeFromHourMinute,
  MyCheckApp,
  useLanguageStore,
  useLocationStore,
  useNavigation,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Background, CustomIcon, Loader } from '@components';

import { Header as MobileHeader } from '../../../../components/Header/Header';

import styles from './MoreInfo.module.scss';

interface MoreInfoItemProps {
  icon: JSX.Element;
  value: string | JSX.Element;
}

const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const MoreInfoItem: React.FC<MoreInfoItemProps> = ({ icon, value }) => (
  <div className={styles.item}>
    <div className={styles.itemIcon}>{icon}</div>
    <div className={styles.itemMain}>
      <div className={styles.itemValue}>{value}</div>
    </div>
  </div>
);

interface Props {
  restaurantId?: number;
}

export const MoreInfo: React.FC<Props> = observer(({ restaurantId }) => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.title', {});
    const iconColor = get(_config, 'palette.primary');

    return {
      titleStyle,
      iconColor,
    };
  }, [location.search]);

  const restaurant = LocationStore.restaurantList.filter(
    (restaurantObj) =>
      restaurantObj.id === (restaurantId ?? LocationStore.moreInfo),
  )[0];

  const { isLg } = useWindowSize();

  const handleClose = () => {
    LocationStore.setMoreInfo(null);

    if (!isLg) {
      navigation.goBack('/location');
    }
  };

  const currentExperience = restaurant?.experiences.find(
    (exp) => exp.type === LocationStore.selectedExperienceType,
  );
  const openingHours = currentExperience?.settings.open_hours;

  const longDescription =
    !!restaurant &&
    LanguageStore.getProductTranslation(
      restaurant.id,
      LocationStore.productId,
      'data.long_description',
      '',
    );
  const shortDescription =
    !!restaurant &&
    LanguageStore.getProductTranslation(
      restaurant.id,
      LocationStore.productId,
      'data.short_description',
      '',
    );
  const description =
    longDescription ||
    shortDescription ||
    restaurant?.long_description ||
    restaurant?.short_description;

  const openingHoursDays = days.map((day) => (
    <div key={day} className={styles.hoursItem}>
      <p className={styles.hoursItemDay}>{t(`location.${day}`)}</p>
      <p className={styles.hoursItemTime}>
        {openingHours[day]
          ? openingHours[day].map((hours, index) => (
              <span className={styles.hoursItemTimeItem} key={index}>
                {hours.from === '00:00' && hours.to === '23:59'
                  ? t('location.open24h')
                  : `${displayTimeFromHourMinute(
                      hours.from,
                      LocationStore.localTimeFormat,
                    )} - ${displayTimeFromHourMinute(
                      hours.to,
                      LocationStore.localTimeFormat,
                    )}`}
              </span>
            ))
          : t('location.closedLabel')}
      </p>
    </div>
  ));

  const experienceNameTranslated =
    t(`experience.${currentExperience.type}`) ||
    capitalizeFirstLetter(currentExperience.type);

  return (
    <Background
      className={classnames(styles.root, {
        [styles.rootWithImage]: !!restaurant.image,
        [styles.rootLg]: isLg,
        [styles.rootHasIframe]: LocationStore.isIframe,
      })}
      testId="more-info-content"
    >
      {!restaurant ? (
        <Loader />
      ) : (
        <>
          {!isLg && (
            <MobileHeader
              headerTitle={LanguageStore.getBusinessTranslation(
                restaurant.id,
                'name',
                restaurant.name,
              )}
              enforceSmall
              style={config.titleStyle}
              handleBackClick={handleClose}
              rootStyle={{ position: 'fixed', width: '100%', top: 0 }}
            />
          )}

          {!!restaurant.image && (
            <div
              className={styles.image}
              style={{ backgroundImage: `url("${restaurant.image}")` }}
            />
          )}
          <div className={isLg ? styles.bodyLg : styles.body}>
            {!isLg && (
              <p className={styles.title}>
                {LanguageStore.getBusinessTranslation(
                  restaurant.id,
                  'name',
                  restaurant.name,
                )}
              </p>
            )}
            <div className={styles.description}>{description}</div>
            <div className={styles.contacts}>
              <MoreInfoItem
                icon={
                  <CustomIcon
                    name="location"
                    style={{ color: config.iconColor }}
                  />
                }
                value={restaurant.place.data.formatted_address}
              />
              {!!restaurant.phone && (
                <MoreInfoItem
                  icon={
                    <CustomIcon
                      name="phone"
                      style={{ color: config.iconColor }}
                    />
                  }
                  value={
                    <a href={`tel:${restaurant.phone}`}>
                      {parsePhoneNumber(restaurant.phone).formatInternational()}
                    </a>
                  }
                />
              )}
            </div>
            <div className={styles.hours}>
              <div className={styles.hoursTitle}>
                {t('location.hours', {
                  experience: experienceNameTranslated,
                })}
              </div>
              <div className={styles.hoursBody}>{openingHoursDays}</div>
            </div>
          </div>
        </>
      )}
    </Background>
  );
});

MoreInfo.displayName = 'MoreInfo';
